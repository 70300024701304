<template>
  <div
    class="mt-1 profilePage"
    style="overflow: auto; max-height: calc(100vh - 100px)"
  >
    <div class="d-flex justify-content-between">
      <div style="">
        <h4>{{ $t("My Profile") }}</h4>
      </div>
    </div>
    <div class="profile-container">
      <div class="left-side" style="flex: 1">
        <div style="padding: 10px 30px">
          <ContentLoader
            view-box="0 0 200 160"
            v-if="loading"
            :speed="2"
            primaryColor="rgb(230, 230, 230)"
            secondaryColor="rgb(240, 240, 240)"
            style="margin-top: 40px"
          />
          <div class="d-flex justify-content-center" v-else>
            <div class="avatar-container">
              <DefaultAvatar
                :primaryColor="avatar.skinTone"
                :selectedHair="avatar.selectedHair"
                :topClothe="avatar.selectedTopClothes"
                :bottomClothe="avatar.selectedBottomClothes"
                :facialHair="avatar.selectedFacialHair"
                :facialExpression="avatar.selectedExpression"
                :facialHairPosition="facialHairPosition"
                :shoes="avatar.selectedShoes"
              />
            </div>

            <i
              v-if="view === 'profile'"
              @click="changeView"
              class="fas fa-pen"
              style="color: #007aff; font-size: 22px; cursor: pointer"
            ></i>
            <div v-else @click="changeView">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 12"
                fill="none"
                style="cursor: pointer"
              >
                <path
                  d="M0.251763 0.251763C0.331366 0.171957 0.425932 0.10864 0.530044 0.0654386C0.634156 0.0222369 0.745768 0 0.858488 0C0.971207 0 1.08282 0.0222369 1.18693 0.0654386C1.29104 0.10864 1.38561 0.171957 1.46521 0.251763L6.00022 4.78849L10.5352 0.251763C10.6149 0.172086 10.7095 0.108884 10.8136 0.065763C10.9177 0.0226426 11.0293 0.000448674 11.142 0.000448674C11.2546 0.000448674 11.3662 0.0226426 11.4703 0.065763C11.5744 0.108884 11.669 0.172086 11.7487 0.251763C11.8284 0.331439 11.8916 0.426028 11.9347 0.53013C11.9778 0.634232 12 0.745808 12 0.858488C12 0.971167 11.9778 1.08274 11.9347 1.18685C11.8916 1.29095 11.8284 1.38554 11.7487 1.46521L7.21196 6.00022L11.7487 10.5352C11.8284 10.6149 11.8916 10.7095 11.9347 10.8136C11.9778 10.9177 12 11.0293 12 11.142C12 11.2546 11.9778 11.3662 11.9347 11.4703C11.8916 11.5744 11.8284 11.669 11.7487 11.7487C11.669 11.8284 11.5744 11.8916 11.4703 11.9347C11.3662 11.9778 11.2546 12 11.142 12C11.0293 12 10.9177 11.9778 10.8136 11.9347C10.7095 11.8916 10.6149 11.8284 10.5352 11.7487L6.00022 7.21196L1.46521 11.7487C1.3043 11.9096 1.08605 12 0.858488 12C0.630922 12 0.412676 11.9096 0.251763 11.7487C0.0908491 11.5878 0.000448674 11.3695 0.000448674 11.142C0.000448674 10.9144 0.0908491 10.6961 0.251763 10.5352L4.78849 6.00022L0.251763 1.46521C0.171957 1.38561 0.10864 1.29104 0.0654386 1.18693C0.0222369 1.08282 0 0.971207 0 0.858488C0 0.745768 0.0222369 0.634156 0.0654386 0.530044C0.10864 0.425932 0.171957 0.331366 0.251763 0.251763Z"
                  fill="#BF062E"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="user-info">
          <div>
            <el-text style="font-weight: bold; font-size: 20px; color: black">{{
              userData.name
            }}</el-text>
          </div>
          <div>
            <el-text style="font-size: 18px">
              <svg
                style="margin-right: 5px"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 2.75C0.5 2.08696 0.763392 1.45107 1.23223 0.982233C1.70107 0.513392 2.33696 0.25 3 0.25H18C18.663 0.25 19.2989 0.513392 19.7678 0.982233C20.2366 1.45107 20.5 2.08696 20.5 2.75V12.75C20.5 13.413 20.2366 14.0489 19.7678 14.5178C19.2989 14.9866 18.663 15.25 18 15.25H3C2.33696 15.25 1.70107 14.9866 1.23223 14.5178C0.763392 14.0489 0.5 13.413 0.5 12.75V2.75ZM3 1.5C2.66848 1.5 2.35054 1.6317 2.11612 1.86612C1.8817 2.10054 1.75 2.41848 1.75 2.75V3.02125L10.5 8.27125L19.25 3.02125V2.75C19.25 2.41848 19.1183 2.10054 18.8839 1.86612C18.6495 1.6317 18.3315 1.5 18 1.5H3ZM19.25 4.47875L13.365 8.01L19.25 11.625V4.47875ZM19.2075 13.0737L12.1575 8.735L10.5 9.72875L8.8425 8.72875L1.7925 13.0662C1.86236 13.3334 2.01873 13.5699 2.23717 13.7388C2.45562 13.9078 2.72386 13.9996 3 14H18C18.2752 14 18.5426 13.9093 18.761 13.7418C18.9793 13.5743 19.1362 13.3395 19.2075 13.0737ZM1.75 11.625L7.635 8L1.75 4.47875V11.625Z"
                  fill="#7E8181"
                />
              </svg>
              {{ userData.email }}
            </el-text>
          </div>
        </div>
      </div>
      <!--right side-->
      <div
        v-if="view === 'editAvatar'"
        class="cardsContainer"
        style="width: 50%"
      >
        <div class="editAvatarCards border-0 shadow-lg">
          <div class="editAvatarSectionsSelect">
            <ul>
              <li
                @click="changeEditAvatarSection('hair')"
                :class="{ active: activeEditAvatarSection === 'hair' }"
              >
                {{ $t("Hair") }}
              </li>
              <li
                @click="changeEditAvatarSection('skin color')"
                :class="{ active: activeEditAvatarSection === 'skin color' }"
              >
                {{ $t("Skin color") }}
              </li>
              <li
                @click="changeEditAvatarSection('facial expression')"
                :class="{
                  active: activeEditAvatarSection === 'facial expression',
                }"
              >
                {{ $t("Facial expression") }}
              </li>
              <li
                @click="changeEditAvatarSection('facial hair')"
                :class="{ active: activeEditAvatarSection === 'facial hair' }"
              >
                {{ $t("Facial hair") }}
              </li>
              <li
                @click="changeEditAvatarSection('top clothes')"
                :class="{ active: activeEditAvatarSection === 'top clothes' }"
              >
                {{ $t("Top clothes") }}
              </li>
              <li
                @click="changeEditAvatarSection('bottom clothes')"
                :class="{
                  active: activeEditAvatarSection === 'bottom clothes',
                }"
              >
                {{ $t("Bottom clothes") }}
              </li>
              <li
                @click="changeEditAvatarSection('shoes')"
                :class="{ active: activeEditAvatarSection === 'shoes' }"
              >
                {{ $t("Shoes") }}
              </li>
            </ul>
          </div>
          <div>
            <div class="editAvatarSection">
              <div v-if="activeEditAvatarSection === 'hair'">
                <HairSection
                  :avatarDefaultHair="avatar.selectedHair"
                  @changeHair="changeAvatarHairstyle"
                />
              </div>
              <div v-if="activeEditAvatarSection === 'skin color'">
                <SkinSection @changeSkin="changeSkinTone" />
              </div>
              <div v-if="activeEditAvatarSection === 'facial hair'">
                <FacialHairSection
                  :defaultFacialHair="avatar.selectedFacialHair"
                  @changeFacialHair="changeFacialHair"
                />
              </div>
              <div v-if="activeEditAvatarSection === 'facial expression'">
                <FacialExpression
                  @changeExpression="changeFacialExpression"
                  :skin="avatar.skinTone"
                />
              </div>
              <div v-if="activeEditAvatarSection === 'shoes'">
                <ClothesPicker
                  :items="shoesPicker"
                  @changeClothes="changeClothes"
                  :defaultAvatarPreview="avatar.selectedShoes"
                  :feature="'shoes'"
                />
              </div>
              <div v-if="activeEditAvatarSection === 'top clothes'">
                <ClothesPicker
                  :items="topPicker"
                  @changeClothes="changeClothes"
                  :feature="'top'"
                />
              </div>
              <div v-if="activeEditAvatarSection === 'bottom clothes'">
                <ClothesPicker
                  :items="bottomPicker"
                  :defaultAvatarPreview="avatar.selectedBottomClothes"
                  @changeClothes="changeClothes"
                  :feature="'bottom'"
                />
              </div>
            </div>
            <div
              class="d-flex align-center full-width justify-content-end"
              v-if="view === 'editAvatar'"
            >
              <CButton
                class="text-white btn-save px-4 mb-2"
                @click="submitAvatar"
                >{{ $t("Save avatar") }}</CButton
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else style="flex: 1">
        <div v-if="loading" style="padding: 10px 30px">
          <ContentLoader
            view-box="0 0 200 160"
            :speed="2"
            primaryColor="rgb(230, 230, 230)"
            secondaryColor="rgb(240, 240, 240)"
            style="margin-top: 40px"
          />
        </div>
        <div v-else>
          <div
            style="
              background: white;
              border: 1px solid #c3c3c3;
              padding: 30px 30px 10px 30px;
            "
          >
            <el-text style="font-size: 20px; font-weight: bold; color: #434343"
              >Statuses</el-text
            >
            <div class="dials justify-content-around mt-3">
              <CardStatusMe
                :isProfilePage="true"
                :needToReload="this.needToReload"
              />
              <CardStatus
                :isProfilePage="true"
                :needToReload="this.needToReload"
              />
              <CardMyTraining :isProfilePage="true" />
              <CardTeamTraining :isProfilePage="true" />
            </div>
          </div>

          <div>
            <div v-if="loading" style="padding: 10px 30px">
              <ContentLoader
                view-box="0 0 500 100"
                v-if="loading"
                :speed="2"
                primaryColor="rgb(230, 230, 230)"
                secondaryColor="rgb(240, 240, 240)"
                style="margin-top: 30px"
              ></ContentLoader>
            </div>

            <div
              style="margin-top: 20px; display: flex; gap: 20px"
              class="firstBadgesRow"
            >
              <div
                style="
                  flex: 1;
                  background: white;
                  border: 1px solid #c3c3c3;
                  padding: 20px;
                "
              >
                <div>
                  <el-text
                    style="font-size: 24px; font-weight: bold; color: #434343"
                    >Getting Started</el-text
                  >
                </div>
                <div
                  class="d-flex flex-row justify-content-center flex-wrap trophies"
                  v-if="badgeObtained.length > 0"
                >
                  <el-carousel
                    indicator-position="none"
                    height="100px"
                    style="width: 100%; margin-top: 20px"
                  >
                    <el-carousel-item
                      v-for="badge in onboardingBadges.filter((badge) =>
                        isBadgeObtained(badge.name)
                      )"
                      :key="badge.name"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        flex-direction: column;
                        height: 100px;
                      "
                    >
                      <el-tooltip :content="badge.title" effect="dark">
                        <div
                          justify="center"
                          v-html="getBadgeSvg(badge.name)"
                          class="carouselBadge biggerSvg"
                        ></div>
                      </el-tooltip>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background: white;
                  border: 1px solid #c3c3c3;
                  padding: 20px;
                "
              >
                <div>
                  <el-text
                    style="font-size: 24px; font-weight: bold; color: #434343"
                    >Challenges</el-text
                  >
                </div>
                <div
                  class="d-flex flex-row justify-content-center flex-wrap"
                  v-if="badgeObtained.length > 0"
                >
                  <el-carousel
                    indicator-position="none"
                    height="auto"
                    style="width: 100%; margin-top: 20px"
                  >
                    <el-carousel-item
                      v-for="item in badgeObtained.filter((item) => {
                        return item.challenge_type != 'default';
                      })"
                      :key="item"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        flex-direction: column;
                        height: 100px;
                      "
                    >
                      <el-tooltip :content="item.title" effect="dark">
                        <div
                          justify="center"
                          v-html="getBadgeSvg(item.badge)"
                          class="carouselBadge biggerSvg"
                          :class="{
                            bronze_badge: item.color === 'Bronze',
                            silver_badge: item.color === 'Silver',
                            gold_badge: item.color === 'Gold',
                            teal_badge: item.color === 'Teal',
                            light_green_badge: item.color === 'Light Green',
                          }"
                        ></div>
                      </el-tooltip>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
            <div
              style="margin-top: 20px; display: flex; gap: 20px"
              class="firstBadgesRow"
            >
              <div
                style="
                  flex: 1;
                  background: white;
                  border: 1px solid #c3c3c3;
                  padding: 20px;
                "
              >
                <div>
                  <el-text
                    style="font-size: 24px; font-weight: bold; color: #434343"
                    >Actions</el-text
                  >
                </div>
                <div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Individual
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in userActionsBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getActionsObtainedBadge(
                                badge.name,
                                'user'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 20px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Team
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in teamActionsBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getActionsObtainedBadge(
                                badge.name,
                                'team'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background: white;
                  border: 1px solid #c3c3c3;
                  padding: 20px;
                "
              >
                <div>
                  <el-text
                    style="font-size: 24px; font-weight: bold; color: #434343"
                    >Trainings</el-text
                  >
                </div>
                <div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Individual
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in userTrainingBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getTrainingsObtainedBadge(
                                badge.name,
                                'user'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 20px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Team
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in teamTrainingBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getTrainingsObtainedBadge(
                                badge.name,
                                'team'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { images } from "../../assets/Images/images";
import DefaultAvatar from "./DefaultAvatar.vue";
import { hair } from "./sections/hair/hairStyle";
import HairSection from "./sections/hair/HairSection.vue";
import SkinSection from "./sections/skin/SkinSection.vue";
import FacialHairSection from "./sections/facialHair/FacialHairSection.vue";
import { facialHair } from "./sections/facialHair/facialHair";
import FacialExpression from "./sections/facialExpression/FacialExpression.vue";
import { facialExpression } from "./sections/facialExpression/facialExpression";
import { shoes, shoesPicker } from "./sections/shoes/shoes";
import { topClothes, topClothesPicker } from "./sections/topClothes/topClothes";
import ClothesPicker from "./sections/ClothesPicker.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import {
  bottomClothes,
  bottomClothesPicker,
} from "./sections/bottomClothes/bottomClothes";
import { ProfileService } from "@/services/profile";
import { OrganizationsService } from "@/services/organizations";
import { ContentLoader } from "vue-content-loader";
import { showMessage, updateMyData } from "@/utils/helpers";

import { mapGetters, mapState } from "vuex";
import { getPickerData, clothesTypes, clothesColors } from "../../utils/avatar";
import CardStatus from "../home/components/content/CardStatus.vue";
import CardStatusMe from "../home/components/content/CardStatusMe.vue";
import CardMyTraining from "../home/components/content/CardMyTraining.vue";
import CardTeamTraining from "../home/components/content/CardTeamTraining.vue";

import { useAvatarStore } from "../../stores/AvatarStore";
import { mapWritableState } from "pinia";
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  name: "Profile",
  components: {
    ClothesPicker,
    FacialExpression,
    FacialHairSection,
    SkinSection,
    HairSection,
    DefaultAvatar,
    ContentLoader,
    OrganizationSelector,
    CardStatus,
    CardStatusMe,
    CardMyTraining,
    CardTeamTraining,
  },
  data() {
    return {
      default_badges: {
        first_login_to_app: "first_login_to_app",
      },
      avatarStore: useAvatarStore(),
      needToReload: false,
      badges: [
        { label: "Star", id: "star" },
        { label: "Silver Medal", id: "silver_medal" },
        { label: "Cup", id: "cup" },
        { label: "Gold Medal", id: "gold_medal" },
      ],
      badgeObtained: [],
      selectedBadgeSvg: null,
      loading: true,
      activeEditAvatarSection: "hair",
      view: "profile",
      star: images.challenges.star,
      medal: images.challenges.medal,
      cup: images.challenges.cup,
      medalBadge: images.challenges.medalBadge,
      obtainedMedal: false,
      obtainedCup: false,
      obtainedStar: false,
      obtainedMedalBadge: false,
      name: "",
      events: [],
      challenges: [],
      statusChart: {
        user_actions: 92,
        user_trainings: 0,
        team_actions: 45,
        team_trainings: 1,
      },
      userData: {
        name: "",
        email: "",
        role: "",
        organizations: "",
      },
      roles: [
        {
          organization: "EDF",
          project: "ERP integration",
          role: "Accountant",
          level: "0",
          total: "5000",
          current: "3400",
        },
        {
          organization: "EDF",
          project: "CRM integration",
          role: "Accountant",
          level: "2",
          total: "10000",
          current: "4500",
        },
        {
          organization: "EDF",
          project: "ERP integration",
          role: "Conduite de changement",
          level: "0",
          total: "1500",
          current: "900",
        },
      ],
      project: [
        {
          organization: "EDF",
          project: "CRM integration",
          level: "0",
          total: "",
          current: "",
        },
        {
          organization: "EDF",
          project: "ERP integration",
          level: "5",
          total: "",
          current: "",
        },
      ],
      achievements: [
        {
          name: "Early bird",
          icon: "cup",
        },
        {
          name: "7 days in a row",
          icon: "cup",
        },
        {
          name: "Actions completed",
          icon: "medal",
        },
      ],
      collaborators: [
        { value: "Tout les collaborateurs", text: "tout" },
        { value: "Collaborateur 1", text: "Collaborateur 1" },
        {
          value: "Collaborateur 2",
          text: "Collaborateur 2",
        },
      ],
      avatarHair: hair,
      facialHair: facialHair,
      facialExpression: facialExpression,
      shoes: shoes,
      top: topClothes,
      bottom: bottomClothes,
      topPicker: topClothesPicker,
      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
      bottomClothesPositionLeft: "",
      facialHairPosition: "",
      showOptions: false,
      onboardingBadges: [
        {
          id: 1,
          name: "first_action_by_user_to_self",
          title: "First Action By User To Self",
        },
        {
          id: 2,
          name: "first_action_completed",
          title: "First Action Completed",
        },
        { id: 3, name: "first_avatar_change", title: "First Avatar Change" },
        {
          id: 4,
          name: "first_event_in_agenda",
          title: "First Event In Agenda",
        },
        { id: 5, name: "first_login_to_app", title: "First Login To App" },
        { id: 6, name: "first_news_seen", title: "First News Seen" },
        { id: 7, name: "logged_in_X_times", title: "Logged In X Times" },
      ],
      userActionsBadges: [
        {
          id: 1,
          name: "user_actions_bronze",
          title: "Bronze Actions Badge (user)",
        },
        {
          id: 2,
          name: "user_actions_silver",
          title: "Silver Actions Badge (user)",
        },
        {
          id: 3,
          name: "user_actions_gold",
          title: "Gold Actions Badge (user)",
        },
      ],
      teamActionsBadges: [
        {
          id: 1,
          name: "team_actions_bronze",
          title: "Bronze Actions Badge (team)",
        },
        {
          id: 2,
          name: "team_actions_silver",
          title: "Silver Actions Badge (team)",
        },
        {
          id: 3,
          name: "team_actions_gold",
          title: "Gold Actions Badge (team)",
        },
      ],
      userTrainingBadges: [
        {
          id: 1,
          name: "user_training_bronze",
          title: "Bronze Training Badge (user)",
        },
        {
          id: 2,
          name: "user_training_silver",
          title: "Silver Training Badge (user)",
        },
        {
          id: 3,
          name: "user_training_gold",
          title: "Gold Training Badge (user)",
        },
      ],
      teamTrainingBadges: [
        {
          id: 1,
          name: "training_team_bronze",
          title: "Bronze Training Badge (team)",
        },
        {
          id: 2,
          name: "team_training_silver",
          title: "Silver Training Badge (team)",
        },
        {
          id: 3,
          name: "team_training_gold",
          title: "Gold Training Badge (team)",
        },
      ],
    };
  },
  computed: {
    images() {
      return images;
    },
    ...mapWritableState(useAvatarStore, {
      avatar: "avatar",
      topClothesPosition: "topClothesPosition",
      bottomClothesPosition: "bottomClothesPosition",
    }),
    ...mapState({
      userInfo: (state) => state.userData,
      includedOrgs: (state) => state.includedOrgs,
      currentOrganization: (state) => state.selectedOrg,
      jobsPoints: (state) => state.jobsPoints,
      selectedJob: (state) => state.selectedJob,
      userPoints: (state) => state.userPoints,
    }),
    bottomPicker() {
      const bottomClothes = {};
      const color =
        this.avatar?.selectedBottomClothes?.color || clothesColors[0];
      Object.entries(clothesTypes).forEach(([, val]) => {
        bottomClothes[val] = getPickerData(val, color, "bottom");
      });
      return bottomClothes;
    },
    shoesPicker() {
      const shoes = {};
      const color = this.avatar?.selectedShoes?.color || clothesColors[0];
      Object.entries(clothesTypes).forEach(([, val]) => {
        shoes[val] = getPickerData(val, color, "shoes");
      });
      return shoes;
    },
  },
  async created() {
    this.getBadgeObtained();
    this.setAvatarValues({});
    const data = this.userInfo;
    this.totalPoints = this.userInfo.total_points;
    this.totalPointsActual = parseFloat(this.totalPoints.split("/")[0].trim());
    this.totalPointsOverall = parseFloat(this.totalPoints.split("/")[1].trim());

    this.savedAvatarId = data.avatar;
    this.userData.name = data.firstname + " " + data.lastname;
    this.name = data.firstname + " " + data.lastname;
    this.userData.email = data.email;
    this.userData.role = data.job_description;
    this.userData.organizations = data.organizations;
    this.userData.organizations.map((org) => ({
      value: org,
      text: org,
    }));
    //Me card
    this.project[0].current = data.points.user.collected;
    this.project[0].total = data.points.user.total;

    //Team card
    this.project[1].current = data.points.team.collected;
    this.project[1].total = data.points.team.total;
    this.getAvatar();
    this.getOrgUser();
  },
  watch: {
    currentOrganization() {
      this.getJobDescriptions();
    },
  },
  mounted() {
    this.getJobDescriptions();
    window.addEventListener("resize", this.updateSidebarPosition);
  },
  methods: {
    getActionsObtainedBadge(badge, type) {
      if (type === "user") {
        const userActionsPoints = this.userInfo.total_points;
        const points = userActionsPoints.split(" / ");
        const percentage = (parseInt(points[0]) / parseInt(points[1])) * 100;
        if (percentage >= 100) {
          return true;
        }

        if (percentage >= 66) {
          return badge.includes("silver") || badge.includes("bronze");
        } else if (percentage >= 33) {
          return badge.includes("bronze");
        }
        return false;
      } else {
        const teamActionsData = this.userInfo.level_points_v2.find((role) => {
          return (
            role.job === this.userInfo.current_level_role &&
            role.level === this.userInfo.current_level
          );
        });
        if (!teamActionsData) {
          return false;
        }

        if (teamActionsData.badge.includes("gold")) {
          return true;
        } else if (teamActionsData.badge.includes("silver")) {
          return badge.includes("silver") || badge.includes("bronze");
        } else if (teamActionsData.badge.includes("bronze")) {
          return badge.includes("bronze");
        }
        return false;
      }
    },
    getTrainingsObtainedBadge(badge, type) {
      if (type === "user") {
        const userTrainingPoints = this.userInfo.training_points;
        const currentPoints = userTrainingPoints.current;
        const totalPoints = userTrainingPoints.total;
        const percentage = (currentPoints / totalPoints) * 100;
        if (percentage >= 100) {
          return true;
        }
        if (percentage >= 66) {
          return badge.includes("silver" || badge.includes("bronze"));
        } else if (percentage >= 33) {
          return badge.includes("bronze");
        }
        return false;
      } else {
        const teamTrainingData = this.userInfo.training_points;
        const neededTrainingBadge = teamTrainingData.badge;
        if (!neededTrainingBadge) {
          return false;
        }
        if (neededTrainingBadge.includes("gold")) {
          return true;
        } else if (neededTrainingBadge.includes("silver")) {
          return badge.includes("silver") || badge.includes("bronze");
        } else if (neededTrainingBadge.includes("bronze")) {
          return badge.includes("bronze");
        }
      }
    },
    isBadgeObtained(badge) {
      return this.badgeObtained.some(
        (badgeObj) =>
          badgeObj.badge.toLowerCase() === badge.toLowerCase() &&
          badgeObj.challenge_type === "default"
      );
    },
    getBadgeObtained() {
      this.badgeObtained = this.userInfo.challenges;
    },
    getBadgeSvg(badgeId) {
      let img = this.images.challenges[badgeId];
      if (!img) return;
      return img;
    },
    getJobDescriptions() {
      let jobDescriptions = [];

      // Helper function to recursively search the tree
      const searchTree = (node) => {
        // Check members for a matching email and collect job descriptions
        if (node.members) {
          node.members.forEach((member) => {
            if (member.email === this.userData.email) {
              // Assuming job_description is a property of member
              jobDescriptions.push(member.job_description);
            }
          });
        }

        if (node.children) {
          node.children.forEach((child) => {
            searchTree(child);
          });
        }
      };

      this.includedOrgs?.data?.forEach((org) => {
        if (org._id === this.currentOrganization._id) {
          org.tree.forEach((rootNode) => {
            searchTree(rootNode);
          });
        }
      });
      this.allJobDescriptions = Object.keys(this.userInfo?.level_points);
    },
    async getOrgUser() {
      const { data, res } =
        await OrganizationsService.list_organizations_and_projects();
    },
    async getAvatar() {
      try {
        const { data, res } = await ProfileService.get_avatar({
          user: this.userData.email,
        });
        this.avatarStore.setAvatarValues(data.data.avatar_data);
        // this.setAvatarValues(data.data.avatar_data);
      } catch (error) {
        console.error("error getting avatr", error);
      } finally {
        this.loading = false;
      }
    },
    setAvatarValues(av = {}) {
      const isObject = (val) => typeof val === "object";
      const {
        selectedExpression,
        selectedHair,
        selectedFacialHair,
        selectedBottomClothes,
        selectedShoes,
        skinTone,
        selectedTopClothes,
        ...rest
      } = av;
      this.avatar = { ...rest };
      this.avatar.selectedExpression = isObject(selectedExpression)
        ? selectedExpression
        : { style: "facial_expression_3", color: "#FFB176" };
      this.avatar.selectedShoes = isObject(selectedShoes)
        ? selectedShoes
        : { style: "type_1" };
      this.avatar.selectedTopClothes = selectedTopClothes || "clothe_13";
      this.avatar.selectedBottomClothes = isObject(selectedBottomClothes)
        ? selectedBottomClothes
        : { style: "type_1" };
      this.avatar.skinTone = skinTone || "#FFB176";
      this.avatar.selectedHair = isObject(selectedHair)
        ? selectedHair
        : { style: "", color: "" };
      this.avatar.selectedFacialHair = isObject(selectedFacialHair)
        ? selectedFacialHair
        : { style: "", color: "" };
    },
    changeAvatarHairstyle(style, color) {
      if (style === "none") {
        this.avatar.selectedHair = {
          style: "",
          color: "",
        };
      } else {
        this.avatar.selectedHair = {
          style,
          color,
        };
      }
    },

    changeSkinTone(color) {
      this.avatar.skinTone = color;
    },

    changeFacialHair(style, color) {
      if (style === "none") {
        this.avatar.selectedFacialHair = {
          style: "",
          color: "",
        };
      } else {
        this.avatar.selectedFacialHair = {
          style,
          color,
        };
      }
    },
    changeFacialExpression(style, color) {
      if (style === "none") {
        this.avatar.selectedExpression = {
          style: "",
          color: "",
        };
      } else {
        this.avatar.selectedExpression = {
          style,
          color,
        };
      }
    },
    changeClothes(item, color, feature) {
      switch (feature) {
        case "top":
          this.avatar.selectedTopClothes = item;
          break;
        case "bottom":
          this.avatar.selectedBottomClothes = {
            style: item,
            color,
          };
          break;
        case "shoes":
          this.avatar.selectedShoes = {
            style: item,
            color,
          };
          break;
      }
    },

    changeEditAvatarSection(section) {
      this.activeEditAvatarSection = section;
    },
    changeView() {
      if (this.view === "editAvatar") {
        this.view = "profile";
      } else {
        this.view = "editAvatar";
      }
    },

    async submitAvatar() {
      const { data, res } = await ProfileService.save_avatar({
        avatar: this.avatar,
        user: this.userData.email,
      });
      if (!data.success) {
        showMessage("error", this.$t("Error saving avatar!"));
      } else {
        showMessage("success", this.$t("Avatar successfully saved"));
        updateMyData();
      }
    },
  },
};
</script>

<style scoped>
.biggerSvg {
  scale: 1.4;
}
::v-deep .bronze_badge circle {
  fill: #e2a963;
}
::v-deep .bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .light_green_badge path:first-child {
  fill: #7feb45;
}
</style>

<style lang="scss" scoped>
.cards_status {
  display: flex;
  gap: 10px;
}
.editAvatarCards {
  display: flex;
  flex-direction: row;

  .editAvatarSection {
    max-width: 511px;
    max-height: 600px;
    overflow: auto;
    padding: 15px;
  }

  .editAvatarSectionsSelect {
    width: 30%;
    padding: 15px 10px;
    text-align: center;
    ul {
      list-style-type: none;

      padding-left: 0;
      font-weight: 500;
      li {
        cursor: pointer;
        margin-bottom: 5px;
        border: 1px solid #c3c3c3;
        border-radius: 30px;
        padding: 5px 10px 5px 10px;
        &:not(:last-child) {
          border-bottom: 1px solid #c3c3c3;
        }
        &:hover {
          color: #6381da;
        }
      }
      li.active {
        background: #0071ce;
        color: white;
        border-bottom-right-radius: 20px;
      }
    }
  }
}
.left-side {
  margin-left: auto;
  margin-right: auto;
}
.profile-container {
  display: flex;
  justify-content: space-around;
}
.rounded-div {
  border-radius: 50px;
  padding: 1px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.rounded-div {
  background-color: #6381da;
  color: white;
  border: solid 1px;
}
.cardsContainer {
  margin-left: auto;
  margin-right: auto;
}

.cards-loading {
  padding: 0;
}
.card-info {
  background-color: #26305e;
  padding: 10px;
  border-radius: 10px;
  /* max-width: 20rem */
}
.card-info-loading {
  padding: 0;
}
p {
  font-size: 17px;
  // color: #ebebeb;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.card-content {
  display: flex;
  color: #fff;
  /* justify-content: space-between; */
}
.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.team {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.form-select {
  padding: 17px 20px;
  align-items: center;
  margin-right: 125px;
}

.badge-card {
  /* max-width: 20rem; */
  //background: #fff;
}
.badge-not-obtained {
  filter: grayscale(100%);
  opacity: 0.5;
}
.cards-tm {
  display: flex;
}

.userName {
  font-size: 17px;
  color: #616161;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0px;
}

.userEmail {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
}

.dials {
  display: flex;
}

@media (max-width: 1434px) {
  .form-select {
    margin-right: 0;
  }
  .card-info {
    margin: 0 auto 25px;
  }
  .badge-card {
    margin-left: auto;
    margin-right: auto;
  }

  .cards-tm {
    flex-direction: column;
    align-items: center;
  }
  .card-info {
    width: 20rem;
  }
  .badge-card {
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 720px) {
  .profile-container {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .badge-card {
    margin-left: auto;
    margin-right: auto;
  }
}

.btn-save {
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
  margin-top: 24px;
  margin-right: 17px;
}
.orgProjectDropdown {
  width: max-content;
  position: relative;
  bottom: 20px;
  left: 20px;
}
@media (max-width: 1133px) {
  .orgProjectDropdown {
    width: max-content;
    position: relative;
    top: 0;
    left: 20px;
  }
}
.orgProjectOption {
  color: gray;
  padding: 10px;
  cursor: pointer;
}
.orgProjectOption:hover {
  background: #f5f5f5;
  border-radius: 5px;
}
.orgProjectOption:active {
  background: #e6e6e6;
}
.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 970px) {
  .profilePage {
    margin-top: 50px !important;
    max-height: calc(100vh - 350px);
  }
  .firstBadgesRow {
    display: flex;
    flex-direction: column;
  }
}
</style>
