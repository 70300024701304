<template>
  <div>
    <div class="skinOptions">
      <div
        v-for="(color, index) in skinColors"
        :key="index"
        class="skinOption"
        :style="{ 'background-color': color }"
        @click="this.$emit('changeSkin', color)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      avatarSkinColor: "",
      skinColors: [
        "#FAEED1",
        "#f8e8b9",
        "#ffdbac",
        "#FFD3B1",
        "#f1c27d",
        "#FFB176",
        "#FDAF9F",
        "#be9167",
        "	#a88165",
        "#8c624c",
        "#673e2f",
        "#583123",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.skinOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .skinOption {
    cursor: pointer;
    display: flex;
    justify-content: center; /* Aligns children horizontally in the center */
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid rgb(212, 212, 212);
    height: 100px;
    width: 100px;
  }
}
</style>
