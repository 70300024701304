<template>
  <div>
    <span>Color {{ facialHairStyle.color }}</span>
    <div class="hairColorOptions">
      <div
        class="hairColorOption"
        v-for="color in hairColors"
        :style="'background-color:' + color"
        @click="changeFacialHairStyle(facialHairStyle.style, color)"
      ></div>
    </div>
  </div>
  <div>
    <div class="facialHairOptions">
      <div
        class="facialHairOption"
        v-html="facialHair.none"
        @click="this.$emit('changeFacialHair', 'none', null)"
      ></div>
      <div
        v-for="[key, value] in Object.entries(facialHair).slice(1)"
        :key="key"
        class="facialHairOption"
        @click="changeFacialHairStyle(key, facialHairStyle.color)"
      >
        <div v-html="colorizeSvg(value)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { facialHair } from "./facialHair";

export default {
  props: {
    defaultFacialHair: Object,
  },
  data() {
    return {
      facialHairStyle: {
        style: "none",
        color: "#000000",
      },
      facialHair: facialHair,
      hairColors: [
        "#000000",
        "#DF784C",
        "#683824",
        "#FAE5B0",
        "#0071CE",
        "#FF748C",
        "#097969",
        "#7F00FF",
        "#e5e500",
      ],
    };
  },
  watch: {
    defaultFacialHair: {
      handler(newVal) {
        this.facialHairStyle.style = newVal.style;
        this.facialHairStyle.color = newVal.color;
      },
      immediate: true,
    },
  },
  methods: {
    colorizeSvg(svgString) {
      return svgString.replace(
        /fill="[^"]*"/g,
        `fill="${this.facialHairStyle.color}"`
      );
    },
    changeFacialHairStyle(style, color) {
      this.facialHairStyle.style = style;
      this.facialHairStyle.color = color;
      this.$emit("changeFacialHair", style, color);
    },
  },
};
</script>
<style lang="scss" scoped>
.facialHairOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .facialHairOption {
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Aligns children horizontally in the center */
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid rgb(212, 212, 212);;
    height: 100px;
    width: 100px;
  }
}

.hairColorOptions {
  display: flex;
  flex-direction: row;
 flex-wrap: wrap;
  .hairColorOption {
    width: 32px;
    height: 32px;
    margin-right: 10px;
   margin-top: 10px;
    // border: 1px solid black;
    cursor: pointer;

    &:hover {
      border: 1px solid rgb(185, 185, 185);
    }
  }
}
</style>
