<template>
<!--  <div>-->
<!--    <span>Color {{ head.color }}</span>-->
<!--    <div class="expressionColorOptions">-->
<!--      <div-->
<!--        class="expressionColorOption"-->
<!--        v-for="color in skinColors"-->
<!--        :style="'background-color:' + color"-->
<!--        @click="changeExpression(head.expression, color)"-->
<!--      ></div>-->
<!--    </div>-->
<!--  </div>-->
  <div>
    <div class="facialExpressionOptions">
      <div
        v-for="[key, value] in Object.entries(facialExpressionPicker).slice(1)"
        :key="key"
        class="facialExpressionOption"
        @click="changeExpression(key, this.skin)"
      >
        <div v-html="colorizeSvg(value)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {facialExpression, facialExpressionPicker} from "./facialExpression";

export default {
    props:{
        skin: String
    },
    data() {
        return {
            head: {
                expression: facialExpression.facial_expression_1,
                color:'#FFD3B1'
            },
            // skinColors: ["#FFD3B1", "#FFB176", "#FDAF9F", "#A8514F"],
            facialExpressions: facialExpression,
            facialExpressionPicker: facialExpressionPicker
        }
    },

    methods:{
        colorizeSvg(svgString) {
            return svgString.replace(
                /fill="currentColor"/g,
                `fill="${this.skin}"`
            );
        },
        changeExpression(style, color){
            this.head.expression = style
            this.head.color = color
            this.$emit('changeExpression', style, color)
        }
    }
}
</script>
<style lang="scss" scoped>
.facialExpressionOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .facialExpressionOption {
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Aligns children horizontally in the center */
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid rgb(212, 212, 212);;
    height: 100px;
    width: 100px;
  }
}

//.expressionColorOptions {
//  display: flex;
//  flex-direction: row;
//  .expressionColorOption {
//    width: 32px;
//    height: 32px;
//    margin-right: 10px;
//    // border: 1px solid black;
//    cursor: pointer;
//
//    &:hover {
//      border: 1px solid rgb(185, 185, 185);
//    }
//  }
//}
</style>
