<template>
  <div>
    <div v-if="feature !== 'top'">
      <span>Color {{ avatarPreview.color }}</span>
      <div class="hairColorOptions">
        <div
          class="hairColorOption"
          v-for="color in hairColors"
          :style="'background-color:' + color"
          @click="changeClothes(avatarPreview.style, color)"
        ></div>
      </div>
    </div>
    <div class="options">
      <div
        v-for="[key, value] in Object.entries(items)"
        :key="key"
        class="option"
        @click="changeClothes(key, avatarPreview.color)"
      >
        <div v-html="value"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { clothesColors } from "../../../utils/avatar";

export default {
  props: {
    items: Object,
    feature: String,
    defaultAvatarPreview: Object,
  },
  data() {
    return {
      hairColors: clothesColors,
      avatarPreview: {
        style: "none",
        color: "#000000",
      },
    };
  },
  watch: {
    defaultAvatarPreview: {
      handler(newVal) {
        this.avatarPreview.color = newVal?.color;
        this.avatarPreview.style = newVal?.style;
      },
      immediate: true,
    },
  },
  methods: {
    changeClothes(style, color) {
      this.avatarPreview.style = style;
      this.avatarPreview.color = color;
      this.$emit("changeClothes", style, color, this.feature);
    },
  },
};
</script>
<style lang="scss" scoped>
.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .option {
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Aligns children horizontally in the center */
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid rgb(212, 212, 212);;
    height: 100px;
    width: 100px;
  }
}
.hairColorOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .hairColorOption {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 10px;
    // border: 1px solid black;
    cursor: pointer;

    &:hover {
      border: 1px solid rgb(185, 185, 185);
    }
  }
}
</style>
