<template>
  <div>
    <span>Color {{ avatarPreview.hairColor }}</span>
    <div class="hairColorOptions">
      <div
        class="hairColorOption"
        v-for="color in hairColors"
        :style="'background-color:' + color"
        @click="changeAvatarHairStyle(avatarPreview.hairstyle, color)"
      ></div>
    </div>
  </div>
  <div class="hairstyleOptions">
    <div
      class="hairstyleOption"
      v-html="avatarHairPicker.none"
      @click="this.$emit('changeHair', 'none', null)"
    ></div>
    <div
      @click="changeAvatarHairStyle(key, avatarPreview.hairColor)"
      class="hairstyleOption"
      v-for="[key, value] in Object.entries(avatarHairPicker).slice(1)"
      :key="key"
    >
      <div v-html="colorizeSvg(value)"></div>
    </div>
  </div>
</template>
<script>
import { images } from "../../../../assets/Images/images";
import { hair } from "./hairStyle";

export default {
  props: {
    avatarDefaultHair: Object,
  },
  data() {
    return {
      avatarPreview: {
        hairColor: "#000000",
        hairstyle: "none",
      },
      hairColors: [
        "#000000",
        "#DF784C",
        "#683824",
        "#FAE5B0",
        "#0071CE",
        "#FF748C",
        "#097969",
        "#7F00FF",
        "#e5e500",
      ],
      avatarHairPicker: images.avatar.hair,
      avatarHair: hair,
    };
  },
  watch: {
    avatarDefaultHair: {
      handler(newVal) {
        this.avatarPreview.hairColor = newVal.color;
        this.avatarPreview.hairstyle = newVal.style;
      },
      immediate: true,
    },
  },
  methods: {
    colorizeSvg(svgString) {
      return svgString.replace(
        /fill="[^"]*"/g,
        `fill="${this.avatarPreview.hairColor}"`
      );
    },
    changeAvatarHairStyle(style, color) {
      this.avatarPreview.hairstyle = style;
      this.avatarPreview.hairColor = color;
      this.$emit("changeHair", style, color);
    },
  },
};
</script>
<style lang="scss" scoped>
.hairstyleOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .hairstyleOption {
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Aligns children horizontally in the center */
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid rgb(212, 212, 212);
    height: 100px;
    width: 100px;
  }
}

.hairColorOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .hairColorOption {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 10px;
    // border: 1px solid black;
    cursor: pointer;

    &:hover {
      border: 1px solid rgb(46, 46, 46);
    }
  }
}
</style>
