import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const PROFILE_ENDPOINTS = {
  save_avatar: `${BASE_URL}api/profile/save_avatar`,
  get_avatar: `${BASE_URL}api/profile/get_avatar`,
  get_all_avatars: `${BASE_URL}api/profile/get_all_avatars`,
};

class Profile {
  async save_avatar({ avatar, user }) {
    var { data, res } = await request({
      url: PROFILE_ENDPOINTS.save_avatar,
      method: "POST",
      skipRedirect: true,
      body: { avatar, user },
    });
    return { data, res };
  }

  async get_avatar({ user }) {
    var { data, res } = await request({
      url: PROFILE_ENDPOINTS.get_avatar,
      method: "POST",
      skipRedirect: true,
      body: { user },
    });
    return { data, res };
  }

  async get_all_avatars() {
    var { data, res } = await request({
      url: PROFILE_ENDPOINTS.get_all_avatars,
      method: "GET",
      skipRedirect: true,
    });
    return { data, res };
  }
}

export const ProfileService = new Profile();
